#cards {
  width: 100%;
  height: 500px;
  margin: 3% 0% 2%;
  display: flex;
  justify-content: center;
  & > div {
    width: fit-content;
    margin-right: 4%;
  }
  & > div:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 760px) {
    display: block;
    height: 100%;
    & > div {
      width: 90%;
      margin: 5%;
    }
  }
  
  .card {
    height: 100%;
    .divImg {
      position: relative;
      height: 80%;
      margin-bottom: 4%;
      aspect-ratio: 1/1;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .title {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 14px;
    }
    .button {
      text-decoration: underline;
      font-size: 13px;
    }
  }
}
