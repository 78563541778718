.header {
  position: fixed;
  z-index: 4;
  width: 100%;
  background-color: transparent;
  color: white;
  & > div {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  &.displayBackground {
    background-color: white;
    color: black;
    transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  }

  .header-menu {
    display: inline-flex;
  }
  
  #logo-header {
    padding-top: 10px;
    img {
      width: 80%;
      margin-left: 10%;
    }
  }
}
