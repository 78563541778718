#section-home {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-image: url("https://files.auzier.silverscreenshot.com/auzier-entete.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  & > div {
    text-align: center;
    position: absolute;
    bottom: 10%;
    color: white;
    left: 50%;
    transform: translate(-50%);
    & > div:first-child {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 14px;
    }
    & > div:nth-child(2) {
      font-size: 28px;
      font-weight: 700;
      padding: 1% 0 5%;
    }
    button {
      border: 2px solid white;
      background-color: rgba(0, 0, 0, 0.2);
      color: white;
      padding: 12px 45px;
      border-radius: 25px;
      font-weight: 600;
    }
  }
}
