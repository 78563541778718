#footer {
  width: 100%;
  & > div {
    display: flex;
    justify-content: center;
  }

  #logo-footer {
    padding-top: 10px;
    border-top: 1px solid black;
    img {
      width: 40%;
      margin-left: 30%;
    }
  }
  
  .menu-footer {
    display: inline-flex;
    border-top: 1px solid black;
  }
}
