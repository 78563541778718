.menu-main {
  position: relative;
  padding: 0 1em;
  & > a:first-child {
    display: flex;
    & > h5 {
      position: relative;
      margin: 10px 0;
    }
  }
  &.headerType {
    text-transform: uppercase;
  }
  &.footerType {
    & > a:first-child {
      & > h5 {
        font-weight: 500;
      }
    }
  }
}

.menu-list {
  position: fixed;
  display: none;
  &.active {
    display: block;
    color: black;
  }
  background-color: white;
  & > div {
    background-color: white;
  }
  margin: -2px 0px 0 -5px;
  padding: 0 10px;
}
