.section {
  width: 100vw;
  height: 80vh;
  position: relative;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: grayscale(100%);
  .bgcolor {
    width: 100vw;
    height: 80vh;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    z-index: 2;
  }
  .info {
    text-align: center;
    position: absolute;
    top: 50%;
    color: white;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    & > div:first-child {
      text-transform: uppercase;
      font-weight: bolder;
      font-size: 48px;
      letter-spacing: 1.3px;
    }
    & > div:nth-child(2) {
      font-size: 14px;
      padding: 1% 20% 4%;
    }
    button {
      border: 2px solid white;
      background-color: rgba(0, 0, 0, 0.2);
      color: white;
      padding: 12px 45px;
      border-radius: 25px;
      font-weight: 600;
    }
  }
}
